// Generated by Framer (33d5032)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jes3YGVf7", "EiPw8AZS4"];

const serializationHash = "framer-x5SFt"

const variantClassNames = {EiPw8AZS4: "framer-v-es8tzg", jes3YGVf7: "framer-v-1ib4qbi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Mobile Menu [Open]": "EiPw8AZS4", "Mobile Menu": "jes3YGVf7"}

const getProps = ({height, iconColor, id, tap, width, ...props}) => { return {...props, E6NV4Gf2f: tap ?? props.E6NV4Gf2f, lYvpkC7IW: iconColor ?? props.lYvpkC7IW ?? "var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11))", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jes3YGVf7"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, E6NV4Gf2f, lYvpkC7IW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jes3YGVf7", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapey0dds = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E6NV4Gf2f) {
const res = await E6NV4Gf2f(...args);
if (res === false) return false;
}
setVariant("EiPw8AZS4")
})

const onTap108nq5d = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E6NV4Gf2f) {
const res = await E6NV4Gf2f(...args);
if (res === false) return false;
}
setVariant("jes3YGVf7")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ib4qbi", className, classNames)} data-framer-name={"Mobile Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"jes3YGVf7"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapey0dds} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({EiPw8AZS4: {"data-framer-name": "Mobile Menu [Open]", onTap: onTap108nq5d}}, baseVariant, gestureVariant)}><motion.div className={"framer-1cux5of"} layoutDependency={layoutDependency} layoutId={"axZqjz8iD"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{EiPw8AZS4: {rotate: 45}}}/><motion.div className={"framer-1w1bul6"} layoutDependency={layoutDependency} layoutId={"NLO8BEcKq"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{EiPw8AZS4: {rotate: -45}}}/><motion.div className={"framer-1ku2ucf"} layoutDependency={layoutDependency} layoutId={"eoR2V6lm3"} style={{backgroundColor: lYvpkC7IW, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 1, rotate: 0}} variants={{EiPw8AZS4: {opacity: 0, rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-x5SFt.framer-3vm2ug, .framer-x5SFt .framer-3vm2ug { display: block; }", ".framer-x5SFt.framer-1ib4qbi { cursor: pointer; height: 48px; position: relative; width: 48px; }", ".framer-x5SFt .framer-1cux5of { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 14px; width: 26px; }", ".framer-x5SFt .framer-1w1bul6 { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 22px; width: 26px; }", ".framer-x5SFt .framer-1ku2ucf { flex: none; height: 3px; left: 11px; overflow: visible; position: absolute; top: 30px; width: 19px; }", ".framer-x5SFt.framer-v-es8tzg.framer-1ib4qbi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 48px); }", ".framer-x5SFt.framer-v-es8tzg .framer-1cux5of { left: calc(50.00000000000002% - 26px / 2); top: 23px; }", ".framer-x5SFt.framer-v-es8tzg .framer-1w1bul6 { top: 23px; }", ".framer-x5SFt.framer-v-es8tzg .framer-1ku2ucf { bottom: 18px; height: unset; left: 13px; width: 13px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EiPw8AZS4":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"E6NV4Gf2f":"tap","lYvpkC7IW":"iconColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersDquhqjHT: React.ComponentType<Props> = withCSS(Component, css, "framer-x5SFt") as typeof Component;
export default FramersDquhqjHT;

FramersDquhqjHT.displayName = "Menu/Hamburger Menu";

FramersDquhqjHT.defaultProps = {height: 48, width: 48};

addPropertyControls(FramersDquhqjHT, {variant: {options: ["jes3YGVf7", "EiPw8AZS4"], optionTitles: ["Mobile Menu", "Mobile Menu [Open]"], title: "Variant", type: ControlType.Enum}, E6NV4Gf2f: {title: "Tap", type: ControlType.EventHandler}, lYvpkC7IW: {defaultValue: "var(--token-963ba7ff-1506-4ab8-a9bc-332b05614e66, rgb(9, 9, 11)) /* {\"name\":\"Gray 950\"} */", title: "Icon Color", type: ControlType.Color}} as any)

addFonts(FramersDquhqjHT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})